const ApiPath = {
  AuthApiPath: {
    USER_SIGNUP: "/users",
    LOGIN_USER: "/users/login",
    VERIFY_OTP: "/users/verify",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD_OTP: "/users/reset-password-otp-verify",
    RESET_PASSWORD: "/users/reset-password",
    CREATE_NEW_PASSWORD: "/users/change-password",
    LOGOUT_USER: "/users/logOut",
    RESEND_OTP: "/users/resend",
    DELETE_ACCOUNT: "/users/",
    CHEF_SETUP_PROFILE: "/users/add-chef-detail",
    CHEF_SETUP_PROFILE_DOCUMENT: "/users/upload-file",
    CHEF_PROFILE_DETAILS: "/users",
    UPDATE_CHEF_PROFILE: "/users",
    GET_EXPERTISE: "/expertise/all",
    ADD_BANK_DETAILS: "/transactions/add-account",
    GET_BANK_DETAILS: "/transactions/bank-details",
    UPDATE_BANK_DETAILS: "/transactions/account-links",
  },
  webApiPath: {
    CHEF_LIST: "/users/chefs",
    MENUS: "/menus",
    GET_FILES_URL: "/menus/upload-image",
    EDIT_MENU_ITEM: "/menus",
    SINGLE_CHEF_DETAIL: "/users/chefDetail",
    GET_SLOT_DETAIL: "/bookings/availability",
    TOGGLE_AVAILABILTY: "/users/verify-hire",
  },
  userApiPath: {
    ADD_ADDRESS: "/user_delivery_address",
    GET_ADDRESS: "/user_delivery_address/all",
    EDIT_ADDRESS: "/user_delivery_address",
    GET_SINGLE_ADDRESS: "/user_delivery_address",
    CONTACT_US: "/support_tickets/support-ticktes",
    HELPER_PAGES: "/helperPages/get-by-slug",
    HELPER_PAGES_BY_SLUG: "/helperPages/slug",
    ADD_CART: "/cart",
    ALL_CART: "/cart/cart",
    REMOVE_CART: "/cart/remove-item",
    ALL_CART_COUNT: "/cart/my-cart",
    MENU_ORDER: "/order",
    CANCEL_ORDER: "/order/change-status",
    GET_ALL_ORDER: "/order/user",
    GET_SINGLE_ORDER: "/order/order",
    UPDATE_CART_ITEM: "/cart/update",
    ADD_RATING: "/chefRating",
    GET_RATING: "/chefRating/rating",
    REPORT_CHAT: "/report_chat/report",
    HIRE_CHEF: "/bookings",
    CANCEL_BOOKING: "/bookings/change-status",
    GET_ALL_NOTIFICATION: "/notification/notification",
    READ_NOTIFICATION: "/notification",
    CLEAR_ALL_NOTIFICATION: "/notification",
    MENU_RATING: "/menuRating",
    GET_MENU_RATING: "/menuRating/rating",
    GET_LOCATION_INFO: "json",
    GET_LOCATION_INFO_FREE: "reverse",
    GET_LOCATION_INFO_GOOGLE_MAP: "/maps/api/geocode/json",
    GET_ALL_CART_NOTIFICATION: "/users/home",
    CREATE_BOOKING_ORDER: "/order/checkout",
    CREATE_PAYMENT_INTENT: "/transactions/create-intent",
    GET_ALL_BOOKING: "/bookings/user",
    GET_ALL_CARDS: "/users/get-cards",
    CREATE_CARD:"/users/create-card",
    DELETE_CARD:"/users/delete-card"
  },
  chefApiPath: {
    GET_RECENT_ORDER: "/order/chef",
    ACCEPT_ORDER: "/order/change-status",
    GET_CHEF_SINGLE_ORDER: "/order/order",
    CONFIRM_ORDER_OTP: "/order/deliver",
    RESEND_OTP: "/order/resend",
    RESEND_BOOKING_OTP: "/bookings/resend",
    GET_BOOKING_REQUESTS: "/bookings/chef",
    GET_BOOKING_DETAIL: "/bookings/booking",
    ACCEPT_BOOKING: "/bookings/change-status",
    CONFIRM_BOOKING_OTP: "/bookings/verify",
  },
};

export default ApiPath;

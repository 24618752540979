import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const PARENTCOLLECTIONNAME = "chats";
const USERPARENTCOLLECTION = "users";
const CHILDCOLLECTIONNAME = "messages";
const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export {
  db,
  storage,
  app,
  messaging,
  PARENTCOLLECTIONNAME,
  USERPARENTCOLLECTION,
  CHILDCOLLECTIONNAME,
  VAPID_KEY,
};
